import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PostWrapper from "../../components/postWrapper"
import PostList from "../../components/postList"
import PostSidebar from "../../components/postSidebarFilter"
import Pagination from "../../components/pagination"

export const query = graphql`
  query ($slug: String, $skip: Int, $limit: Int) {
    allSanityResource(filter: {category: {slug: {current: {eq: $slug}}}}, sort: {fields: publishDate, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          title
          slug { current }
          category {
            title
            slug { current }
          }
          _rawThumbnail(resolveReferences: {maxDepth: 10})
          resource { label }
        }
      }
    }
  }
`

const ResourceIndex = (props) => {
  const resources = props.data.allSanityResource.edges
  const { currentPage, totalPages, title, slug } = props.pageContext

  return (
    <Layout theme="light">
      <SEO title={title} customTitle={`${title} | Learning Analytics Resources | Watershed`} />
      <Hero heroTitle="Learning Analytics Resources" theme="light" graph="radar" />
      <PostWrapper>
        <PostList items={resources} theme="brandAlpha" type="resources" alt />
        <PostSidebar theme="brandAlpha" type="resources" />
      </PostWrapper>
      {totalPages > 1 && <Pagination base={`resources/${slug}/`} currentPage={currentPage} totalPages={totalPages} />}
    </Layout>
  )
}

export default ResourceIndex
